import { DepositTokenApy } from '../constants/apy'
import { depositTokenConfig, DepositTokenId } from '../constants/tokens'

type LidoResponse = {
  data: {
    timeUnix: number
    apr: number
  }
}

export const fetchWstEthApy = async (): Promise<number> => {
  const res = await fetch('https://eth-api.lido.fi/v1/protocol/steth/apr/last')
  const aprRes = (await res.json()) as LidoResponse
  // Format as decimal
  const lidoApr = aprRes.data.apr / 100
  return lidoApr
}

export const fetchDepositTokensApy = async (): Promise<DepositTokenApy> => {
  const lidoApr = await fetchWstEthApy()

  return Object.values(DepositTokenId).reduce((map, tokenId) => {
    const tokenConfig = depositTokenConfig[tokenId]
    let apy
    if (tokenConfig.hasApy) {
      switch (tokenId) {
        case DepositTokenId.ETH:
        case DepositTokenId.WSTETH:
        case DepositTokenId.WETH:
          apy = lidoApr
          break
        default:
          throw new Error(`APY method not defined for ${tokenId}`)
      }
    }
    return {
      ...map,
      [tokenId]: tokenConfig.hasApy ? apy : 0,
    }
  }, {} as DepositTokenApy)
}
