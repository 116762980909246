import {
  PrivateExecuteQuoteParamsSchema,
  PrivateExecuteQuoteParamsSchemaNoSignature,
} from '@lyra/core/api/types/private.execute_quote'
import { PrivateOrderParamsSchema } from '@lyra/core/api/types/private.order'
import { SECONDS_IN_DAY, SECONDS_IN_MINUTE } from '@lyra/core/constants/time'
import generateNonce from '@lyra/core/utils/generateNonce'
import toBigNumber from '@lyra/core/utils/toBigNumber'
import { PrivateSendQuoteParamsSchemaNoSignature } from '@lyra/web/constants/rfqs'
import { lyraContractAddresses } from '@lyra/web/src/constants/contracts'
import { ethers } from 'ethers'
import { Address, encodeAbiParameters, PrivateKeyAccount } from 'viem'

import { PrivateSendQuoteParamsSchema } from '../../../core/api/types/private.send_quote'
import { LIQUIDATE_DATA_ABI } from '../constants/auctions'
import { lyraChain } from '../constants/chains'
import { PartialTickerForSigning } from '../constants/instruments'
import { OrderParamsWithoutSignature } from '../constants/order'
import { getCollateralId, getMarketId } from './markets'
import { getUtcNowSecs } from './time'

export type SignActionParams = {
  chainId: number
  signingAccount: PrivateKeyAccount
  owner: Address
  subaccountId: number
  encodedData: Address
  contract: 'deposit' | 'withdraw' | 'trade' | 'transfer' | 'rfq' | 'liquidate'
  signatureExpiry?: number
}

export type SignedAction = {
  signer: Address
  signature: Address
  nonce: number
  signatureExpiry: number
}

const TRADE_DATA_ABI_TYPES = [
  { type: 'address' },
  { type: 'uint' },
  { type: 'int' },
  { type: 'int' },
  { type: 'uint' },
  { type: 'uint' },
  { type: 'bool' },
]

const ACTION_TYPES = {
  Action: [
    { name: 'subaccountId', type: 'uint256' },
    { name: 'nonce', type: 'uint256' },
    { name: 'module', type: 'address' },
    { name: 'data', type: 'bytes' },
    { name: 'expiry', type: 'uint256' },
    { name: 'owner', type: 'address' },
    { name: 'signer', type: 'address' },
  ],
}

// TODO @michaelxuwu Deprecate this in favour of orderbook timestamp
// 30 days
export const getDefaultSignatureExpiry = () => getUtcNowSecs() + SECONDS_IN_DAY * 30

export const signAction = async ({
  chainId,
  signingAccount,
  owner,
  subaccountId,
  encodedData,
  contract,
  signatureExpiry: _signatureExpiry,
}: SignActionParams): Promise<SignedAction> => {
  const signatureExpiry = !_signatureExpiry ? getDefaultSignatureExpiry() : _signatureExpiry

  const nonce = generateNonce()
  const domain = {
    name: 'Matching',
    version: '1.0',
    chainId,
    verifyingContract: lyraContractAddresses.matching,
  }
  const signer = signingAccount.address
  const message = {
    subaccountId: BigInt(subaccountId),
    nonce: BigInt(nonce),
    module: lyraContractAddresses[contract],
    data: encodedData,
    expiry: BigInt(signatureExpiry),
    owner,
    signer,
  }
  const signature = await signingAccount.signTypedData({
    domain,
    primaryType: 'Action',
    types: ACTION_TYPES,
    message,
  })
  return { signature, nonce, signatureExpiry, signer }
}

export async function signOrder(
  owner: Address,
  signingAccount: PrivateKeyAccount,
  ticker: PartialTickerForSigning,
  {
    amount,
    instrument_name,
    subaccount_id,
    direction,
    order_type,
    limit_price,
    time_in_force,
    max_fee,
    trigger_price,
    trigger_price_type,
    trigger_type,
    reduce_only,
    signature_expiry_sec: inputSignatureExpiry,
  }: OrderParamsWithoutSignature
): Promise<PrivateOrderParamsSchema> {
  const [baseCurrency] = instrument_name.split('-')
  const marketId = getMarketId(baseCurrency)
  const collateralId = getCollateralId(baseCurrency)
  if (!marketId && !collateralId) {
    throw new Error('Unsupported market')
  }

  const tradeData = [
    ticker.base_asset_address,
    ticker.base_asset_sub_id, //sub id
    toBigNumber(+limit_price, 18), // limit
    toBigNumber(+amount, 18), //desired amount
    toBigNumber(+max_fee, 18), // worst fee
    subaccount_id, //recipient id
    direction === 'buy', //isbid
  ]

  const encodedData = encodeAbiParameters(TRADE_DATA_ABI_TYPES, tradeData)

  const { signature, nonce, signer, signatureExpiry } = await signAction({
    chainId: lyraChain.id,
    contract: 'trade',
    encodedData,
    subaccountId: subaccount_id,
    signatureExpiry: inputSignatureExpiry,
    signingAccount,
    owner,
  })

  return {
    instrument_name,
    subaccount_id,
    direction,
    limit_price,
    amount,
    max_fee,
    signature,
    signature_expiry_sec: signatureExpiry,
    order_type,
    nonce,
    signer,
    time_in_force,
    trigger_price,
    trigger_price_type,
    trigger_type,
    reduce_only,
  }
}

type EncodedLeg = [string, string, ethers.BigNumberish, ethers.BigNumberish]
type QuoteLeg = {
  instrument_name: string
  amount: string
  direction: 'buy' | 'sell'
  price: string
}

function encodeExecuteData(encodedLegs: Array<EncodedLeg>, max_fee: string): string {
  const encoder = ethers.AbiCoder.defaultAbiCoder()
  const orderHashABI = ['(address,uint,uint,int)[]']
  const orderHash = ethers.keccak256(
    Buffer.from(encoder.encode(orderHashABI, [encodedLegs]).slice(2), 'hex')
  )
  const ExectuteDataABI = ['bytes32', 'uint']
  const encodedData = encoder.encode(ExectuteDataABI, [orderHash, ethers.parseUnits(max_fee, 18)])
  return encodedData
}

function encodePricedLegs(
  tickers: Record<string, PartialTickerForSigning>,
  legs: Array<QuoteLeg>,
  direction: 'buy' | 'sell'
): EncodedLeg[] {
  const dirSign = BigInt(direction === 'buy' ? 1 : -1)
  const encoded_legs: Array<EncodedLeg> = legs.map((leg) => {
    const legSign = BigInt(leg.direction === 'buy' ? 1 : -1)
    const signedAmount = ethers.parseUnits(leg.amount, 18) * legSign * dirSign
    const ticker = tickers[leg.instrument_name]
    if (!ticker) {
      throw new Error('No ticker in encodePricedLegs')
    }
    return [
      ticker.base_asset_address,
      ticker.base_asset_sub_id,
      ethers.parseUnits(leg.price, 18),
      signedAmount,
    ]
  })
  return encoded_legs
}

export async function signRfqQuote(
  owner: Address,
  signingAccount: PrivateKeyAccount,
  tickers: Record<string, PartialTickerForSigning>,
  { legs, max_fee, quote_id, rfq_id, subaccount_id }: PrivateExecuteQuoteParamsSchemaNoSignature
): Promise<PrivateExecuteQuoteParamsSchema> {
  const encodedLegs = encodePricedLegs(
    tickers,
    legs.map((leg) => ({
      amount: leg.amount,
      direction: leg.direction,
      instrument_name: leg.instrument_name,
      price: leg.price,
    })),
    'sell'
  )

  const executeData = encodeExecuteData(encodedLegs, max_fee)

  const { signature, nonce, signer, signatureExpiry } = await signAction({
    chainId: lyraChain.id,
    contract: 'rfq',
    encodedData: executeData as Address,
    subaccountId: subaccount_id,
    signatureExpiry: Math.floor(Date.now() / 1000 + 350),
    signingAccount,
    owner,
  })
  return {
    // todo @stuontheblockchain : sort out maker/taker for front end is always buy
    direction: 'buy',
    legs: legs,
    max_fee: max_fee,
    nonce: nonce,
    quote_id: quote_id,
    rfq_id: rfq_id,
    signature: signature,
    signature_expiry_sec: signatureExpiry,
    signer: signer,
    subaccount_id: subaccount_id,
  }
}

export async function signSendQuote(
  owner: Address,
  signingAccount: PrivateKeyAccount,
  tickers: Record<string, PartialTickerForSigning>,
  params: PrivateSendQuoteParamsSchemaNoSignature
): Promise<PrivateSendQuoteParamsSchema> {
  const encodedLegs = encodePricedLegs(
    tickers,
    params.legs.map((leg) => ({
      amount: leg.amount,
      direction: leg.direction,
      instrument_name: leg.instrument_name,
      price: leg.price,
    })),
    params.direction
  )
  const QuoteDataABI = ['(uint,(address,uint,uint,int)[])']
  const rfqData = [ethers.parseUnits(params.max_fee, 18), encodedLegs]
  const encoder = ethers.AbiCoder.defaultAbiCoder()
  const encodedData = encoder.encode(QuoteDataABI, [rfqData])

  const { signature, nonce, signer, signatureExpiry } = await signAction({
    chainId: lyraChain.id,
    contract: 'rfq',
    encodedData: encodedData as Address,
    subaccountId: params.subaccount_id,
    signatureExpiry: getUtcNowSecs() + SECONDS_IN_MINUTE * 10,
    signingAccount,
    owner,
  })

  return {
    ...params,
    nonce: nonce,
    signature: signature,
    signature_expiry_sec: signatureExpiry,
    signer: signer,
  }
}

export async function signLiquidateQuote(
  owner: Address,
  signingAccount: PrivateKeyAccount,
  subaccountId: number,
  liquidatedSubaccountId: number,
  priceLimit: string,
  percentBid: string,
  cashTransfer: string,
  lastSeenTradeId: number
) {
  const signatureExpirySec = getUtcNowSecs() + SECONDS_IN_MINUTE * 6

  const liquidateData = [
    liquidatedSubaccountId,
    toBigNumber(+cashTransfer, 18),
    toBigNumber(+percentBid, 18),
    toBigNumber(+priceLimit, 18),
    lastSeenTradeId,
    true,
  ]

  const encoder = ethers.AbiCoder.defaultAbiCoder()
  const encodedData = encoder.encode(LIQUIDATE_DATA_ABI, liquidateData)

  const signedAction = await signAction({
    chainId: lyraChain.id,
    signingAccount,
    owner,
    subaccountId,
    encodedData: encodedData as Address,
    contract: 'liquidate',
    signatureExpiry: signatureExpirySec,
  })

  return signedAction
}
