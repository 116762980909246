import { useCallback, useContext } from 'react'

import { OrderbookTimeContext } from '../providers/OrderbookTimeProvider'
import { getUtcSecs } from '../utils/time'

export default function useOrderbookTimestamp(): {
  getTimestamp: () => number
  getUtcTimestampSec: () => number
} {
  const orderbookTimeOffset = useContext(OrderbookTimeContext)
  const getUtcTimestampSec = useCallback(
    () => getUtcSecs(new Date(Date.now() + orderbookTimeOffset)),
    [orderbookTimeOffset]
  )
  const getTimestamp = useCallback(
    () => new Date().getTime() + orderbookTimeOffset,
    [orderbookTimeOffset]
  )
  return {
    getTimestamp,
    getUtcTimestampSec,
  }
}
